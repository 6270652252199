export const SHOW_ROWS = [10, 25, 50, 100];

export const permission = {
  snopAdd: "SNOP_CREATE",
  snopEdit: "SNOP_EDIT",
  snopView: "SNOP_VIEW",
  snopDelete: "SNOP_DELETE",
};

export const tableData = {
  pageSize: 10,
};

export const createThemeSelect = (theme) => {
  return {
    ...theme,
    borderRadius: 2,
    colors: {
      ...theme.colors,
      primary25: "#F2F2F2",
      primary: "#E94F1C",
    },
  };
};

export const SNOP_EVENTS = {
  FORECAST_TRIGGET: "forecast_trigger"
}

export const SNOP_STATUS = {
  FAILED: "FAILED",
  NOT_YET_TRIGGERED: "NOT_YET_TRIGGERED",
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS"
}

export const RE_FORECAST = "Re-Forecast";

export const snopStatusPopoverId = "snop-popup-dialog";
