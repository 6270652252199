import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  error: "",
  ErrorCode: "",
  isDelete: false,
  isCreated: false,
  snop: [],
  status: {},
  modalOpen: false
};

export const SnopSlice = createSlice({
  name: "snop",
  initialState: initialState,
  reducers: {
    getAllSnopSlice: (state, action) => {
      state.snop = action.payload;
      state.loader = false;
      state.isCreated = false;
      state.isDelete = false;
      return state;
    },
    resetSnopSlice: (state) => {
      return {
        ...initialState,
      };
    },
    editSnopSlice: (state, action) => {
      const { isCreated, data, loader } = action.payload;
      state.snop = isCreated
        ? state.snop.map((i) => (i.snop_id === data.snop_id ? data : i))
        : state.snop;
      state.isCreated = isCreated;
      state.isDelete = false;
      state.loader = loader;
      return state;
    },
    createSnopSlice: (state, action) => {
      const { isCreated, data, loader } = action.payload;
      state.snop = isCreated ? state.snop.push(data) : state.snop;
      state.isDelete = false;
      state.isCreated = isCreated;
      state.loader = loader;
      return state;
    },
    deleteSnopSlice: (state, action) => {
      const { isDelete, data, loader } = action.payload;
      state.isCreated = false;
      state.isDelete = isDelete;
      state.snop = isDelete
        ? state.snop.filter((i) => (i.snop_id !== data ? i.snop_id : i))
        : state.snop;
      state.isDelete = isDelete;
      state.loader = loader;
      return state;
    },
    setSnopError: (state) => {
      state.error = true;
      state.loader = false;
      return state;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
      return state;
    },
    snopStatusSlice: (state, action) => {
      state.status = action.payload
      return state;
    },
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload
      return state;
    }
  },
});

export const {
  getAllSnopSlice,
  deleteSnopSlice,
  createSnopSlice,
  editSnopSlice,
  setLoader,
  setModalOpen,
  snopStatusSlice
} = SnopSlice.actions;

export default SnopSlice.reducer;
