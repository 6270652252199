import "./style/sass/main.scss";

import { createBrowserHistory } from "history";
import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContextProvider from "./ContextProvider";
import Routing from "./routers/Routing";

const defaultHistory = createBrowserHistory();

function App() {
  const [fullscreenEnabled, setfullscreenEnabled] = useState(
    document.fullscreenElement ? true : false
  );

  // Setting fullScreen Boolean when the screen resolution is changing.
  document.addEventListener("fullscreenchange", () => {
    setfullscreenEnabled(document.fullscreenElement ? true : false);
  });

  return (
    <div className="App">
      <ContextProvider.Provider value={{ fullscreenEnabled }}>
        <BrowserRouter history={defaultHistory}>
          <Routing />
          <ToastContainer style={{ marginTop: "10rem", marginRight: "5rem" }} />
        </BrowserRouter>
      </ContextProvider.Provider>
    </div>
  );
}

export default App;
