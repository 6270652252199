import { permissions } from "./config";

export const getFullDate = (dates, num) => {
  const date = new Date(dates || new Date());
  const getTomorrow = date.setDate(date.getDate() + num);
  return new Date(getTomorrow);
};

export const isPermissionAvailable = (permissionName) =>
  permissions?.includes(permissionName);
