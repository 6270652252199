import React from "react";
export const CalenderIcon = () => {
  return (
    <svg viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5222 24H3.06377C2.47856 23.9939 1.91882 23.721 1.505 23.2401C1.09118 22.7592 0.856361 22.1087 0.851074 21.4286V6.55104C0.852136 6.36406 0.916522 6.1851 1.03029 6.05288C1.14406 5.92066 1.29806 5.84584 1.45896 5.8446H22.1432C22.3041 5.84584 22.4581 5.92066 22.5719 6.05288C22.6856 6.1851 22.75 6.36406 22.7511 6.55104V21.4286C22.7457 22.1119 22.5087 22.7652 22.0914 23.2467C21.6741 23.7281 21.1102 23.9988 20.5222 24ZM2.06684 7.25748V21.4286C2.06684 21.7409 2.17358 22.0403 2.36358 22.2612C2.55358 22.482 2.81128 22.606 3.07998 22.606H20.5222C20.788 22.6011 21.0416 22.4748 21.2281 22.2546C21.4146 22.0343 21.5191 21.7376 21.5191 21.4286V7.29986L2.06684 7.25748Z"
        fill="#ACB2BA"
      />
      <path
        d="M22.1432 7.25752H1.44275C1.28435 7.25261 1.13387 7.17602 1.02335 7.04407C0.912833 6.91211 0.851018 6.73523 0.851074 6.55109V3.87134C0.852146 3.18474 1.08732 2.52661 1.50509 2.04111C1.92286 1.55561 2.48917 1.28231 3.07998 1.28107H20.5222C21.113 1.28231 21.6793 1.55561 22.0971 2.04111C22.5148 2.52661 22.75 3.18474 22.7511 3.87134V6.55109C22.7511 6.73845 22.687 6.91813 22.573 7.05061C22.459 7.1831 22.3044 7.25752 22.1432 7.25752ZM2.06684 5.84465H21.5191V3.87134C21.5191 3.55907 21.4124 3.2596 21.2224 3.03879C21.0324 2.81799 20.7747 2.69394 20.506 2.69394H3.06377C2.79789 2.69889 2.54434 2.82511 2.35783 3.04538C2.17132 3.26565 2.06681 3.56231 2.06684 3.87134V5.84465Z"
        fill="#ACB2BA"
      />
      <path
        d="M5.98044 4.4741C5.81922 4.4741 5.6646 4.39967 5.5506 4.26719C5.4366 4.13471 5.37256 3.95502 5.37256 3.76766V0.706437C5.37256 0.519078 5.4366 0.339393 5.5506 0.206911C5.6646 0.0744279 5.81922 0 5.98044 0C6.14166 0 6.29628 0.0744279 6.41028 0.206911C6.52428 0.339393 6.58832 0.519078 6.58832 0.706437V3.76766C6.58832 3.95502 6.52428 4.13471 6.41028 4.26719C6.29628 4.39967 6.14166 4.4741 5.98044 4.4741Z"
        fill="#ACB2BA"
      />
      <path
        d="M17.6025 4.4741C17.4413 4.4741 17.2867 4.39967 17.1727 4.26719C17.0587 4.13471 16.9946 3.95502 16.9946 3.76766V0.706437C16.9946 0.519078 17.0587 0.339393 17.1727 0.206911C17.2867 0.0744279 17.4413 0 17.6025 0C17.7637 0 17.9184 0.0744279 18.0324 0.206911C18.1464 0.339393 18.2104 0.519078 18.2104 0.706437V3.76766C18.2104 3.95502 18.1464 4.13471 18.0324 4.26719C17.9184 4.39967 17.7637 4.4741 17.6025 4.4741Z"
        fill="#ACB2BA"
      />
    </svg>
  );
};
