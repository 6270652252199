import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import {
    Cancel,
    Chat,
    CheckCircle,
    Pending,
    PlayCircle,
} from "@mui/icons-material";
import { IconButton, Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import React, { useContext } from "react";
import ContextProvider from "../../ContextProvider";
import { localeConfiguration } from "../../utils/config";
import { RE_FORECAST, SNOP_STATUS, snopStatusPopoverId } from "../../utils/constants";
import "./calendar.scss";

const Calendar = ({ selectedSnop, snopEvents }) => {

    const popoverDialogBox = document.getElementById(snopStatusPopoverId);

    const { fullscreenEnabled } = useContext(ContextProvider);
    //Custom tooltip style
    const CustomTooltip = styled(({ className, ...props }) => {
        return (
            <Tooltip {...props} classes={{ popper: className }} PopperProps={{ container: fullscreenEnabled ? popoverDialogBox : document.body }} />
        )
    })(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
            background: "#efefef !important",
            fontSize: "1rem !important",
            color: "black",
            padding: "0.4rem 1rem !important",
            fontWeight: '500',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0rem 0.3rem 0.8rem'
        },
        [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
            marginTop: "0.3rem !important",
        },
    }));

    const getStatusIcon = (status) => {
        switch (status) {
            case SNOP_STATUS.FAILED:
                return (
                    <span className="failed">
                        <Cancel />
                    </span>
                );
            case SNOP_STATUS.NOT_YET_TRIGGERED:
                return (
                    <span className="not-triggered">
                        <Chat />
                    </span>
                );
            case SNOP_STATUS.IN_PROGRESS:
                return (
                    <span className="in-progress">
                        <Pending />
                    </span>
                );
            case SNOP_STATUS.SUCCESS:
                return (
                    <span className="success">
                        <CheckCircle />
                    </span>
                );
            default:
                break;
        }
    };

    const renderEventContent = (eventInfo) => {
        const buttonDisabled = eventInfo.event.extendedProps.status !== SNOP_STATUS.FAILED;
        const title = eventInfo.event.extendedProps.remark;
        return (
            <div className="event-container">
                <label>{eventInfo.event.title}</label>
                <CustomTooltip title={title} placement={"top"}>
                    {getStatusIcon(eventInfo.event.extendedProps.status)}
                </CustomTooltip>
                <CustomTooltip title={!buttonDisabled ? RE_FORECAST : ""} placement={"top"}>
                    <IconButton disabled={buttonDisabled}>
                        <PlayCircle
                            className={`${buttonDisabled ? "button-dis" : "button-en"}`}
                        />
                    </IconButton>
                </CustomTooltip>
            </div>
        );
    };

    return (
        <>
            <FullCalendar
                plugins={[dayGridPlugin]}
                headerToolbar={{
                    left: "prev,next",
                    center: "title",
                    right: "",
                }}
                vi
                initialView="dayGridMonth"
                initialDate={`${moment(selectedSnop?.forecast_trigger_date).format(
                    localeConfiguration["dateFormat"]?.toUpperCase()
                )}`}
                validRange={{
                    start: `${moment(selectedSnop?.forecast_trigger_date).format(
                        localeConfiguration["dateFormat"]?.toUpperCase()
                    )}`,
                    end: `${moment(selectedSnop?.from_date).format(
                        localeConfiguration["dateFormat"]?.toUpperCase()
                    )}`,
                }}
                weekends={true}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                events={snopEvents}
                eventContent={renderEventContent}
            />
        </>
    );
};

export default Calendar;
