import PropTypes from "prop-types";
import React from "react";
import { Button } from "../../button";
import CustomModal from "../customModal";

const ConfirmationModal = ({
  title,
  content,
  onSubmit,
  onCancel,
  textSubmit,
  textCancel,
}) => {
  const FooterDialog = () => {
    return (
      <>
        <Button
          buttonType="primary"
          label={textSubmit}
          className={"ml-10"}
          disabled={false}
          onClick={onSubmit}
        />
        <Button
          buttonType={"outline-primary"}
          label={textCancel}
          className="ml-10"
          onClick={onCancel}
          disabled={false}
        />
      </>
    );
  };
  const HeaderDialog = () => {
    return (
      <div>
        <h3 className="common-heading">{title}</h3>
      </div>
    );
  };
  return (
    <>
      <CustomModal
        footer={<FooterDialog />}
        header={<HeaderDialog />}
        width={450}
        actionButtonDir={"end"}
        toggleDialog={onCancel}
        closeIcon={true}
        className={"k-dialog-confirm"}
        appendTo={document.getElementById("snop-listing-page")}
      >
        <p className={"paragrpah-content"}>{content}</p>
      </CustomModal>
    </>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  textSubmit: PropTypes.string,
  textCancel: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  onSubmit: Function.prototype,
  onCancel: Function.prototype,
};

export default ConfirmationModal;
