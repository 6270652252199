import axios from "axios";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { supportedLanguage } from "./constants/constants";


const getJSON = async (url) => {
  const res = await axios.get(url);
  return res.data;
}

const getResources = () => {
  const { REACT_APP_CONTENT_HOST: host } = process.env;
  let languages = {};
  supportedLanguage.forEach(async (lang) => {
    languages[lang] = {
      translation: await getJSON(`${host}/locales/${lang}/translation.json`)
    }
  })
  return languages;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    fallbackLng: "en-US",
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: "",
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
    resources: getResources()
  });

export default i18n;
