import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { lastDayOfMonth } from "@progress/kendo-date-math";
import {
  Calendar,
  CalendarCell,
  DatePicker,
  ToggleButton,
} from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ContextProvider from "../../../ContextProvider";
import { Button } from "../../../components/button/";
import ConfirmationModal from "../../../components/commons/confirmationModal";
import { CalenderIcon } from "../../../components/icons/CalenderIcon";
import LoaderWithLabel from "../../../components/loaderWithLabel";
import QuaterCalendar from "../../../components/quarterCalendar";
import { CREATE_SNOP, DELETE_SNOP, EDIT_SNOP } from "../../../store/types";
import { localeConfiguration, snopConfiguration } from "../../../utils/config";
import { permission } from "../../../utils/constants";
import { getFullDate, isPermissionAvailable } from "../../../utils/helper";
import "../list/style.scss";
import "./style.scss";

export default function Create(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isEdit = props.actionName === "Edit S&OP";

  const toggleDialogedit = () => {
    closeDialogWithCallback();
  };

  //Errors
  const [errors, setErrors] = useState({});
  const [snopObj, SetSnopObj] = useState(props.snop);

  //Create popup & Confirmation popup visible states.
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [visible, setVisible] = useState(props.visible);

  const deletePermission = isPermissionAvailable(permission.snopDelete);

  const { fullscreenEnabled } = useContext(ContextProvider);

  // On mount, update the errors [Required for disabling create button at inital render]
  useEffect(() => {
    if (!isEdit) {
      setErrors((prev) => {
        return {
          ...prev,
          snop_name_inital: t("RequiredValidationMessageSNOPName"),
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]); //todo: Place the translation keyword back in the effect

  const isTriggerDateEdit =
    isEdit && moment(snopObj?.forecast_trigger_date).isBefore(new Date());

  const snopOperations = (type, payload) => {
    dispatch({
      type: "snop/setLoader", payload: true
    });
    dispatch({ type: type, payload: payload });
    props.setEditItem([]);
  };

  const onEditSubmit = () => {
    validateInputs();
    if (Object.keys(errors).length === 0) {
      //snopObj?.updated_at && delete snopObj?.updated_at;
      snopOperations(EDIT_SNOP, snopObj);
    }
  };

  const onCreateSubmit = () => {
    validateInputs();
    if (Object.keys(errors).length === 0) {
      delete snopObj?.updated_at;
      snopOperations(CREATE_SNOP, snopObj);
    }
  };

  const closeDialogWithCallback = () => {
    setVisible(!visible);
    props.snopTableCallback(!visible);
  };

  const onDeleteSnop = () => {
    snopOperations(DELETE_SNOP, snopObj?.snop_id);
  };

  const planningFrequency = snopConfiguration["planningCycleFrequency"];
  const planningHorizon = snopConfiguration["planningHorizon"];
  const weekStartDay = snopConfiguration["weekStart"];

  const setToDate = (value) => {
    let newDate = value;
    // eslint-disable-next-line default-case
    switch (planningFrequency.toUpperCase()) {
      case "DAILY":
        newDate.setDate(value.getDate() + planningHorizon - 1);
        break;
      case "FORTNIGHTLY":
        newDate.setDate(value.getDate() + planningHorizon * 15 - 1);
        break;
      case "WEEKLY":
        newDate.setDate(value.getDate() + planningHorizon * 7 - 1);
        break;
      case "MONTHLY":
        newDate.setMonth(value.getMonth() + planningHorizon - 1);
        newDate = lastDayOfMonth(newDate);
        break;
      case "QUATERLY":
        newDate.setMonth(value.getMonth() + planningHorizon * 3 - 1);
        newDate = lastDayOfMonth(newDate);
        break;
    }
    return moment(newDate).format("YYYY-MM-DD");
  };

  // handing all the inputs changes
  const handleInputChange = (value, name) => {
    if (name === "snop_name") {
      SetSnopObj({ ...snopObj, snop_name: value });
    } else {
      if (value) {
        if (name !== "from_date") {
          SetSnopObj({
            ...snopObj,
            [name]: moment(value).format("YYYY-MM-DD"),
          });
        } else {
          let dateValue = moment(value).format("YYYY-MM-DD");
          if (planningFrequency.toUpperCase() === "MONTHLY") {
            dateValue = moment(value).startOf("month").format("YYYY-MM-DD");
          }
          SetSnopObj({
            ...snopObj,
            [name]: dateValue,
            to_date: setToDate(value),
          });
        }
      }
    }
    return;
  };

  useEffect(() => {
    if (isAnyUpdateOnSnopObj()) {
      validateInputs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snopObj]);

  const isAnyUpdateOnSnopObj = () => {
    return (
      snopObj?.snop_name ||
      snopObj?.demand_review_date ||
      snopObj?.supply_review_date ||
      snopObj?.pre_snop_date ||
      snopObj?.snop_date ||
      snopObj?.from_date ||
      snopObj?.forecast_trigger_date
    );
  };

  // Validates the inputs which given
  const validateInputs = () => {
    if (!snopObj?.snop_name) {
      errors.snop_name = t("RequiredValidationMessageSNOPName");
      setErrors({ ...errors });
    } else {
      delete errors["snop_name"];
      delete errors["snop_name_inital"];
      setErrors({ ...errors });
    }

    if (
      moment(snopObj?.forecast_trigger_date).isSameOrAfter(
        moment(snopObj?.demand_review_date)
      ) ||
      moment(snopObj?.forecast_trigger_date).isSameOrBefore(moment())
    ) {
      if (!isTriggerDateEdit)
        errors.forecast_trigger_date = t("ValidationMessageTriggerDatePReview");

      setErrors({ ...errors });
    } else if (!snopObj?.forecast_trigger_date) {
      errors.forecast_trigger_date = t(
        "RequiredValidationMessageTriggerDatePReview"
      );
      setErrors({ ...errors });
    } else {
      delete errors["forecast_trigger_date"];
      setErrors({ ...errors });
    }

    if (
      moment(snopObj?.demand_review_date).isSameOrAfter(
        snopObj?.supply_review_date
      )
    ) {
      errors.demand_review_date = t("ValidationMessageDemandReview");
      setErrors({ ...errors });
    } else if (!snopObj?.demand_review_date) {
      errors.demand_review_date = t("RequiredValidationMessageDemandReview");
      setErrors({ ...errors });
    } else {
      delete errors["demand_review_date"];
      setErrors({ ...errors });
    }
    if (
      moment(snopObj?.supply_review_date).isSameOrAfter(snopObj?.pre_snop_date)
    ) {
      errors.supply_review_date = t("ValidationMessageSupplyReview");
      setErrors({ ...errors });
    } else if (!snopObj?.supply_review_date) {
      errors.supply_review_date = t("RequiredValidationMessageSupplyReview");
      setErrors({ ...errors });
    } else {
      delete errors["supply_review_date"];
      setErrors({ ...errors });
    }
    if (moment(snopObj?.pre_snop_date).isSameOrAfter(snopObj?.snop_date)) {
      errors.pre_snop_date = t("ValidationMessagePreSNOPReview");
      setErrors({ ...errors });
    } else if (!snopObj?.pre_snop_date) {
      errors.pre_snop_date = t("RequiredValidationMessagePreSNOPReview");
      setErrors({ ...errors });
    } else {
      delete errors["pre_snop_date"];
      setErrors({ ...errors });
    }
    if (moment(snopObj?.snop_date).isSameOrAfter(snopObj?.from_date)) {
      errors.snop_date = t("ValidationMessageSNOPReview");
      setErrors({ ...errors });
    } else if (!snopObj?.snop_date) {
      errors.snop_date = t("RequiredValidationMessageSNOPReview");
      setErrors({ ...errors });
    } else {
      delete errors["snop_date"];
      setErrors({ ...errors });
    }
    if (
      moment(snopObj?.from_date).isSameOrBefore(
        moment(new Date()).format("YYYY-MM-DD")
      )
    ) {
      errors.from_date = t("ValidationMessageFromDate");
      setErrors({ ...errors });
    } else if (!snopObj?.from_date) {
      errors.from_date = t("RequiredValidationMessageFromDate");
      setErrors({ ...errors });
    } else {
      delete errors["from_date"];
      setErrors({ ...errors });
    }
  };

  const disableWeeks = (calendarCellProps) => {
    let disabledDays;
    // eslint-disable-next-line default-case
    switch (weekStartDay.toUpperCase()) {
      case "MONDAY":
        disabledDays = [0, 2, 3, 4, 5, 6];
        break;
      case "TUESDAY":
        disabledDays = [0, 1, 3, 4, 5, 6];
        break;
      case "WEDNESDAY":
        disabledDays = [0, 1, 2, 4, 5, 6];
        break;
      case "THURSDAY":
        disabledDays = [0, 1, 2, 3, 5, 6];
        break;
      case "FRIDAY":
        disabledDays = [0, 1, 2, 3, 4, 6];
        break;
      case "SATURDAY":
        disabledDays = [0, 1, 2, 3, 4, 5];
        break;
      case "SUNDAY":
        disabledDays = [1, 2, 3, 4, 5, 6];
        break;
    }

    const styleValue = disabledDays.includes(calendarCellProps.value.getDay())
      ? { opacity: ".7", "pointer-events": "none" }
      : { fontWeight: "bold" };

    return <CalendarCell {...calendarCellProps} style={styleValue} />;
  };

  const getWeeklyCalendar = (props) => {
    return (
      <Calendar cell={disableWeeks} {...props} views={1} weekNumber={true} />
    );
  };

  const quarterCallback = (data) => {
    handleInputChange(data, "from_date");
  };

  const createFromCalendar = (CustomToggleButton) => {
    let calendarComponent;

    switch (planningFrequency?.toUpperCase()) {
      case "WEEKLY":
        calendarComponent = (
          <DatePicker
            format={localeConfiguration["dateFormat"]}
            disabled={props.actionName === "Edit S&OP" ? true : false}
            calendar={getWeeklyCalendar}
            className={"prevent-select"}
            value={
              snopObj?.from_date !== ""
                ? new Date(snopObj?.from_date ?? "")
                : null
            }
            onChange={(event) => {
              handleInputChange(event.value, "from_date");
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
            toggleButton={CustomToggleButton}
            popupSettings={{
              appendTo: fullscreenEnabled
                ? document.getElementById("snop-listing-page")
                : document.body,
            }}
          />
        );
        break;
      case "MONTHLY":
        calendarComponent = (
          <DatePicker
            format={localeConfiguration["dateFormat"]}
            disabled={props.actionName === "Edit S&OP" ? true : false}
            className={"prevent-select"}
            calendar={(props) => (
              <Calendar
                {...props}
                views={1}
                bottomView="year"
                topView="decade"
              />
            )}
            value={
              snopObj?.from_date !== ""
                ? new Date(snopObj?.from_date ?? "")
                : null
            }
            onChange={(event) => {
              handleInputChange(event.value, "from_date");
            }}
            // renderInput={(params) => <TextField {...params} fullWidth />}
            toggleButton={CustomToggleButton}
            popupSettings={{
              appendTo: fullscreenEnabled
                ? document.getElementById("snop-listing-page")
                : document.body,
            }}
          />
        );

        break;
      case "QUATERLY": // todo Handle label color change when quarterly user is active.
        calendarComponent = (
          <QuaterCalendar
            fromDate={snopObj?.from_date}
            actionName={props.actionName}
            quarterCallback={quarterCallback}
          />
        );

        break;
      default:
        calendarComponent = (
          <DatePicker
            format={localeConfiguration["dateFormat"]}
            disabled={props.actionName === "Edit S&OP" ? true : false}
            name="from_date"
            value={
              snopObj?.from_date !== ""
                ? new Date(snopObj?.from_date ?? "")
                : null
            }
            onChange={(event) => {
              handleInputChange(event.value, "from_date");
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
            toggleButton={CustomToggleButton}
            popupSettings={{
              appendTo: fullscreenEnabled
                ? document.getElementById("snop-listing-page")
                : document.body,
            }}
          />
        );
    }
    return calendarComponent;
  };

  useEffect(() => {
    diableKeydownOnDatePicker();
  }, []);

  // to prevent maual edit in the datepicker
  const diableKeydownOnDatePicker = () => {
    let datePickers = document.querySelectorAll(".dialogCEsnop .k-dateinput");
    for (let index = 0; index < datePickers.length; index++) {
      const datePicker = datePickers[index];
      datePicker.addEventListener("keydown", (e) => {
        e.stopPropagation();
        e.preventDefault();
      });
    }
  };

  const Title = () => {
    return (
      <div className="custom-title-bar">
        {props.actionName === "Edit S&OP"
          ? t("EditSNOPLabel")
          : t("CreateSNOPLabel")}
        <Button buttonType={"close"} onClick={toggleDialogedit} />
      </div>
    );
  };
  const CustomToggleButton = (props) => {
    return (
      <ToggleButton {...props} className={"custom_toggle_calendar_icon"}>
        <CalenderIcon />
      </ToggleButton>
    );
  };
  return (
    <div id="main-dialog-box">
      {visible && (
        <Dialog
          title={<Title /> ?? ""}
          className="dialogsnop dialogCEsnop"
          closeIcon={false}
          appendTo={
            fullscreenEnabled
              ? document.getElementById("snop-listing-page")
              : document.body
          }
        >
          {props.loader && <LoaderWithLabel />}
          <Box className="Snopfield">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} className={"pt-1"}>
                <div className="search-custom-form-control">
                  <TextField
                    fullWidth
                    label={t("S&OPName")}
                    id="S&OP"
                    name="snop_name"
                    value={snopObj?.snop_name ?? ""}
                    onChange={(event) => {
                      handleInputChange(event.target.value, "snop_name");
                    }}
                    inputProps={{ maxLength: 100 }}
                    className={"custom-form-control"}
                    autoComplete="off"
                  />
                  {errors.snop_name && (
                    <span className="error">{errors.snop_name}</span>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <h5 className="section-subheading mb-0">
                  {t("ForecastHorizonLabel")}
                </h5>
              </Grid>

              <Grid item xs={6} md={6}>
                <div className={"custom-datepicker-control"}>
                  <label
                    className={[
                      "label",
                      `${props.actionName === "Edit S&OP" ? "disabled" : ""}`,
                    ].join(" ")}
                  >
                    {t("FromDateLabel")}
                  </label>
                  {createFromCalendar(CustomToggleButton)}
                  {errors.from_date && (
                    <span className="error">{errors.from_date}</span>
                  )}
                </div>
              </Grid>
              <Grid item xs={6} md={6}>
                <div className={"custom-datepicker-control"}>
                  <label className={`label disabled`}>{t("ToDateLabel")}</label>
                  <DatePicker
                    format={localeConfiguration["dateFormat"]}
                    name="to_date"
                    className="prevent-select"
                    disabled
                    value={
                      snopObj?.to_date !== ""
                        ? new Date(snopObj?.to_date)
                        : null
                    }
                    onChange={handleInputChange}
                    // renderInput={(params) => (
                    //   <TextField {...params} fullWidth />
                    // )}
                    toggleButton={CustomToggleButton}
                    popupSettings={{
                      appendTo: fullscreenEnabled
                        ? document.getElementById("snop-listing-page")
                        : document.body,
                    }}
                  />
                  {errors.to_date && (
                    <span className="error">{errors.to_date}</span>
                  )}
                </div>
              </Grid>

              <Grid item xs={6} md={6}>
                <div className={"custom-datepicker-control"}>
                  <label
                    className={`label ${isTriggerDateEdit ? "disabled" : ""}`}
                  >
                    {" "}
                    {t("forecast_trigger_date")}
                  </label>
                  <DatePicker
                    format={localeConfiguration["dateFormat"]}
                    className="calenderedit prevent-select"
                    name="forecast_trigger_date"
                    value={
                      snopObj?.forecast_trigger_date !== ""
                        ? new Date(snopObj?.forecast_trigger_date)
                        : null
                    }
                    onChange={(event) => {
                      handleInputChange(event.value, "forecast_trigger_date");
                    }}
                    // renderInput={(params) => (
                    //   <TextField {...params} fullWidth />
                    // )}
                    toggleButton={CustomToggleButton}
                    popupSettings={{
                      appendTo: fullscreenEnabled
                        ? document.getElementById("snop-listing-page")
                        : document.body,
                    }}
                    disabled={isTriggerDateEdit}
                    min={getFullDate(null, 1)}
                  />
                  {errors.forecast_trigger_date && (
                    <span className="error">
                      {errors.forecast_trigger_date}
                    </span>
                  )}
                </div>
              </Grid>

              <Grid item xs={6} md={6}></Grid>

              <Grid item xs={6} md={6}>
                <div className={"custom-datepicker-control"}>
                  <label className={`label`}>
                    <strong>{t("StepLabel")} 1. </strong>
                    {t("DemandReviewDateLabel")}
                  </label>
                  <DatePicker
                    format={localeConfiguration["dateFormat"]}
                    className="calenderedit prevent-select"
                    name="demand_review_date"
                    value={
                      snopObj?.demand_review_date !== ""
                        ? new Date(snopObj?.demand_review_date)
                        : null
                    }
                    onChange={(event) => {
                      handleInputChange(event.value, "demand_review_date");
                    }}
                    // renderInput={(params) => (
                    //   <TextField {...params} fullWidth />
                    // )}
                    toggleButton={CustomToggleButton}
                    popupSettings={{
                      appendTo: fullscreenEnabled
                        ? document.getElementById("snop-listing-page")
                        : document.body,
                    }}
                    min={
                      isEdit
                        ? getFullDate(snopObj?.forecast_trigger_date, 1)
                        : getFullDate(null, 1)
                    }
                  />
                  {errors.demand_review_date && (
                    <span className="error">{errors.demand_review_date}</span>
                  )}
                </div>
              </Grid>

              {/* // --------------- */}
              <Grid item xs={6} md={6}>
                <div className={"custom-datepicker-control"}>
                  <label className={`label`}>
                    <strong>{t("StepLabel")} 2. </strong>
                    {t("SupplyReviewDateLabel")}
                  </label>
                  <DatePicker
                    format={localeConfiguration["dateFormat"]}
                    className="calenderedit prevent-select"
                    name="supply_review_date"
                    value={
                      snopObj?.supply_review_date !== ""
                        ? new Date(snopObj?.supply_review_date)
                        : null
                    }
                    onChange={(event) => {
                      handleInputChange(event.value, "supply_review_date");
                    }}
                    // renderInput={(params) => (
                    //   <TextField {...params} fullWidth />
                    // )}
                    toggleButton={CustomToggleButton}
                    popupSettings={{
                      appendTo: fullscreenEnabled
                        ? document.getElementById("snop-listing-page")
                        : document.body,
                    }}
                    min={
                      isEdit
                        ? getFullDate(snopObj?.demand_review_date, 1)
                        : getFullDate(null, 1)
                    }
                  />
                  {errors.supply_review_date && (
                    <span className="error">{errors.supply_review_date}</span>
                  )}
                </div>
              </Grid>

              <Grid item xs={6} md={6}>
                <div className={"custom-datepicker-control"}>
                  <label className={`label`}>
                    <strong>{t("StepLabel")} 3. </strong>
                    {t("PreSNOPDateLabel")}
                  </label>
                  <DatePicker
                    format={localeConfiguration["dateFormat"]}
                    className="calenderedit prevent-select"
                    name="pre_snop_date"
                    value={
                      snopObj?.pre_snop_date !== ""
                        ? new Date(snopObj?.pre_snop_date)
                        : null
                    }
                    onChange={(event) => {
                      handleInputChange(event.value, "pre_snop_date");
                    }}
                    // renderInput={(params) => (
                    //   <TextField {...params} fullWidth />
                    // )}
                    toggleButton={CustomToggleButton}
                    popupSettings={{
                      appendTo: fullscreenEnabled
                        ? document.getElementById("snop-listing-page")
                        : document.body,
                    }}
                    min={
                      isEdit
                        ? getFullDate(snopObj?.supply_review_date, 1)
                        : getFullDate(null, 1)
                    }
                  />
                  {errors.pre_snop_date && (
                    <span className="error">{errors.pre_snop_date}</span>
                  )}
                </div>
              </Grid>
              <Grid item xs={6} md={6}>
                <div className={"custom-datepicker-control"}>
                  <label className={`label`}>
                    <strong>{t("StepLabel")} 4. </strong>
                    {t("SNOPDateLabel")}
                  </label>
                  <DatePicker
                    format={localeConfiguration["dateFormat"]}
                    className="calenderedit prevent-select"
                    value={
                      snopObj?.snop_date !== ""
                        ? new Date(snopObj?.snop_date)
                        : null
                    }
                    onChange={(event) => {
                      handleInputChange(event.value, "snop_date");
                    }}
                    // renderInput={(params) => (
                    //   <TextField {...params} fullWidth />
                    // )}
                    toggleButton={CustomToggleButton}
                    popupSettings={{
                      appendTo: fullscreenEnabled
                        ? document.getElementById("snop-listing-page")
                        : document.body,
                    }}
                    min={
                      isEdit
                        ? getFullDate(snopObj?.pre_snop_date, 1)
                        : getFullDate(null, 1)
                    }
                  />
                  {errors.snop_date && (
                    <span className="error">{errors.snop_date}</span>
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>

          <DialogActionsBar>
            <Box>
              <Grid container spacing={2} className="btnsnop">
                <Grid item md={6}>
                  <Button
                    buttonType={"primary"}
                    label={
                      props.actionName === "Edit S&OP"
                        ? t("Update")
                        : t("S&OPCreate")
                    }
                    disabled={Object.keys(errors).length !== 0 ? true : false}
                    onClick={
                      props.actionName === "Edit S&OP"
                        ? onEditSubmit
                        : onCreateSubmit
                    }
                  />
                  <Button
                    buttonType={"outline-primary"}
                    label={t("Cancel")}
                    className={"ml-15"}
                    onClick={toggleDialogedit}
                  />
                </Grid>
                <Grid item md={6}>
                  {props.actionName === "Edit S&OP" && deletePermission && (
                    <Button
                      buttonType={"delete"}
                      label={t("Delete")}
                      onClick={() => setConfirmationModalOpen(true)}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </DialogActionsBar>
        </Dialog>
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          title={t("labelConfirmHeader")}
          content={t("labelConfirmDeleteSNOPMessage")}
          onSubmit={onDeleteSnop}
          onCancel={() => setConfirmationModalOpen(false)}
          textSubmit={t("labelDelete")}
          textCancel={t("labelCancel")}
        />
      )}
    </div>
  );
}
