import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ContextProvider from "./../../../ContextProvider";
import "./style.scss";

const CustomModal = ({
  toggleDialog,
  children,
  footer,
  header,
  width,
  actionButtonDir,
  closeIcon,
  className,
  appendTo,
}) => {
  const { fullscreenEnabled } = useContext(ContextProvider);
  const Title = () => {
    return (
      <div className="custom-title-bar">
        {header}
        {(closeIcon ?? true) && (
          <AiOutlineCloseCircle
            onClick={toggleDialog}
            className="k-icon k-btn1"
          />
        )}
      </div>
    );
  };

  return (
    <Dialog
      title={<Title /> ?? ""}
      onClose={toggleDialog}
      width={width}
      className={`snop-kendo-custom-dialog ${className ?? ""}`}
      closeIcon={false}
      appendTo={fullscreenEnabled ? appendTo || null : document.body}
    >
      {children}
      {footer && (
        <DialogActionsBar layout={actionButtonDir ?? "end"}>
          {footer}
        </DialogActionsBar>
      )}
    </Dialog>
  );
};

CustomModal.propTypes = {
  toggleDialog: PropTypes.func,
  children: PropTypes.object,
  footer: PropTypes.element,
  header: PropTypes.element,
  width: PropTypes.number,
  actionButtonDir: PropTypes.string,
  closeIcon: PropTypes.bool,
  className: PropTypes.string,
  appendTo: PropTypes.any,
};

CustomModal.defaultProps = {
  toggleDialog: Function.prototype,
  children: React.Fragment,
  footer: React.Fragment,
  header: React.Fragment,
  closeIcon: true,
  appendTo: null,
};

export default CustomModal;
