import PropTypes from "prop-types";
import React from "react";
import { Breadcrumb } from "react-bootstrap";
import "./style.scss";

const CustomBreadCrumbs = ({ listOfItems, customClassName }) => {
  return (
    <Breadcrumb className={`breadcrumbs-container ${customClassName}`}>
      {listOfItems?.map((item) => {
        return (
          <Breadcrumb.Item href={item.href} active={item.active}>
            {item.name}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

CustomBreadCrumbs.propTypes = {
  listOfItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      href: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
  customClassName: PropTypes.string,
};

CustomBreadCrumbs.defaultProps = {
  listOfItems: [],
  customClassName: "",
};

export default CustomBreadCrumbs;
