import axios from "axios";
import {
  SCAI_API_BASEURL_DPAI,
  SCAI_API_BASEURL_SNOP,
  SNOP_API_ENDPOINT,
  buId,
  tenantId,
  token,
} from "../utils/config";

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

//SNOP Api's
export const getAllSnopAPI = async () => {
  return axios.get(
    `${SNOP_API_ENDPOINT}${SCAI_API_BASEURL_SNOP}?tenant_id=${tenantId}&bu_id=${buId}`,
    config
  );
};

export const createSnopAPI = async (body) => {
  return axios.post(
    `${SNOP_API_ENDPOINT}${SCAI_API_BASEURL_SNOP}?tenant_id=${tenantId}&bu_id=${buId}`,
    body,
    config
  );
};

export const editSnopAPI = async (body) => {
  return axios.put(
    `${SNOP_API_ENDPOINT}${SCAI_API_BASEURL_SNOP}?tenant_id=${tenantId}&bu_id=${buId}`,
    body,
    config
  );
};

export const deleteSnopAPI = async (snop_id) => {
  return axios.delete(
    `${SNOP_API_ENDPOINT}${SCAI_API_BASEURL_SNOP}?snop_id=${snop_id}&tenant_id=${tenantId}&bu_id=${buId}`,
    config
  );
};

export const snopCalendarAPI = async ({ snop_id }) => {
  return axios.get(
    `${SNOP_API_ENDPOINT}${SCAI_API_BASEURL_DPAI}/status?snop_id=${snop_id}&tenant_id=${tenantId}&bu_id=${buId}`,
    config
  );
};
