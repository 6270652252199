import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ErrorPage from "../components/errorPage";
import Snop from "../pages/snop";
import { user } from "../utils/config";

const Routing = () => {
  let navigate = useNavigate();
  useEffect(() => {
    if (isEmpty(user)) {
      navigate("/error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Routes>
        {/* Public routes */}
        <Route path="/snop" element={<Snop />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default Routing;
