import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import { Grid as GridMUI, InputAdornment, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { process } from "@progress/kendo-data-query";
import { GridColumn as Column, Grid } from "@progress/kendo-react-grid";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { Button } from "../../../components/button/";
import Calendar from "../../../components/calendar";
import CustomBreadCrumbs from "../../../components/commons/customBreadCrumbs";
import PopupDialog from "../../../components/dialog";
import LoaderWithLabel from "../../../components/loaderWithLabel";
import { GET_ALL_SNOP, GET_SNOP_STATUS } from "../../../store/types";
import { activeSnopDetails, localeConfiguration } from "../../../utils/config";
import {
  SHOW_ROWS,
  SNOP_EVENTS,
  createThemeSelect,
  permission,
  tableData,
} from "../../../utils/constants";
import { isPermissionAvailable } from "../../../utils/helper";
import Create from "../create";
import { ReactComponent as TickElement } from "./../../../assets/tick-icon.svg";
import "./style.scss";
import ContextProvider from "../../../ContextProvider";

const List = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { fullscreenEnabled } = useContext(ContextProvider);

  const [editItem, setEditItem] = useState([]);
  const [selectedSnop, setSelectedSnop] = useState(null)
  const [isCreateOrEditClicked, setIsCreateOrEditClicked] = useState(false);
  const [snopEvents, setSnopEvents] = useState({})
  const [actionName, setActionName] = useState();
  const addPermission = isPermissionAvailable(permission.snopAdd);
  const editPermission = isPermissionAvailable(permission.snopEdit);
  const { isDelete, isCreated, loader, snop: snopDetails, modalOpen, status: snopStatus } = useSelector(
    (state) => state.SnopReducer
  );

  // Pagination state & other variables.
  const [pageSize, setPageSize] = useState(tableData.pageSize || 10);

  const [isSnopActivating, setIsSnopActivating] = useState(false);

  const initialDataState = {
    skip: 0,
    take: pageSize,
    sort: [
      {
        field: "snop_name",
        dir: "asc",
      },
    ],
  };

  let SnopObj = {
    snop_name: "",
    from_date: "",
    to_date: "",
    demand_review_date: "",
    supply_review_date: "",
    pre_snop_date: "",
    snop_date: "",
    forecast_trigger_date: "",
    updated_at: "",
  };

  // Sort & filter states.
  const [dataState, setDataState] = useState(initialDataState);
  const [searchField, setSearchField] = useState("");
  const [filteredSnopDetails, setFilteredSnopDetails] = useState([]);

  useEffect(
    () => {
      let filteredSnops = [];
      Array.isArray(snopDetails) &&
        snopDetails.forEach((snop) => {
          if (
            snop?.snop_name?.toLowerCase()?.includes(searchField?.toLowerCase())
          ) {
            filteredSnops.push({
              ...snop,
              isActive: activeSnopDetails?.snop_id === snop?.snop_id,
            });
          }
        });

      setFilteredSnopDetails(filteredSnops);
      if (searchField) setDataState({ ...dataState, skip: 0, take: pageSize });
      else {
        if (filteredSnops?.length % pageSize === 0 && dataState.skip === filteredSnops?.length && dataState.skip >= pageSize)
          setDataState({ ...dataState, skip: dataState.skip - pageSize })

      }
    }, //eslint-disable-next-line
    [searchField, pageSize, JSON.stringify(snopDetails)]
  );

  useEffect(() => {
    if (isDelete || isCreated) {
      setIsCreateOrEditClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDelete, isCreated]);

  useEffect(() => {
    dispatch({ type: "snop/setLoader", payload: true });
    dispatch({ type: GET_ALL_SNOP });
    dispatch({
      type: "snop/setModalOpen",
      payload: false
    });
    dispatch({ type: "snop/snopStatusSlice", payload: {} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(snopStatus).length > 0 && selectedSnop !== null) {

      const defaultStyle = () => {
        return {
          borderColor: "transparent",
          backgroundColor: "transparent"
        }
      }

      let _snopEvents = [];
      Object.keys(snopStatus).forEach((events) => {
        if (events === SNOP_EVENTS.FORECAST_TRIGGET) {
          _snopEvents.push({
            title: "Stat Forecast",
            date: selectedSnop.forecast_trigger_date,
            status: snopStatus[events]?.status,
            remark: snopStatus[events]?.remark,
            ...defaultStyle()
          })
        }
      })

      setSnopEvents(_snopEvents);
    }
  }, [snopStatus])

  useEffect(() => {
    setDataState({ ...dataState, skip: 0, take: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const onActionClick = (event, actionName) => {
    if (actionName === "Create S&OP") {
      // Creating a new object and adding to editItem
      setEditItem([SnopObj]);
    }
    setIsCreateOrEditClicked(true);
    setActionName(actionName);
  };

  const createOrEditCallback = (childDate) => {
    setIsCreateOrEditClicked(childDate);
    setEditItem([]);
    setActionName("Create S&OP");
  };

  const onViewClick = () => {
    setIsSnopActivating(true);
    localStorage.setItem("snop", JSON.stringify(editItem[0]));
    toast.success(t("ActivateSNOPMessage"));
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  const showMoreOptions = SHOW_ROWS.map((item) => ({
    label: `${t("Show")} ${item} ${t("Rows")}`,
    value: item,
  }));

  const onRefresh = useCallback(() => {
    dispatch({ type: GET_ALL_SNOP });
    dispatch({ type: "snop/setLoader", payload: true });
    setSearchField("");
    setPageSize(tableData?.pageSize);
    setEditItem([]);
  }, [dispatch]);

  const handleInfoClick = (snop) => {
    if (!modalOpen) {
      if (!!snop && Object.keys(snop).length > 0 && snop?.snop_id) {
        setSelectedSnop(snop);
        dispatch({
          type: GET_SNOP_STATUS,
          payload: {
            snop_id: snop?.snop_id ?? "",
          },
        });
      }
    }

  }

  const handleInfoClose = () => {
    dispatch({
      type: "snop/setModalOpen",
      payload: false
    })
  }

  return (
    <>
      {loader && <LoaderWithLabel />}
      <div>
        {isCreateOrEditClicked && (
          <Create
            visible={isCreateOrEditClicked}
            snop={editItem[0]}
            snopTableCallback={createOrEditCallback}
            actionName={actionName}
            loader={loader}
            setEditItem={setEditItem}
          />
        )}
      </div>
      <div>
        <section className="snop-container">
          <div className="mb-2">
            <GridMUI container className="row">
              <GridMUI item md={12} sm={12} xs={12}>
                <h3 className="section-heading">{t("S&OPHeader")}</h3>
              </GridMUI>
              <GridMUI item md={12} sm={12} xs={12}>
                <CustomBreadCrumbs
                  listOfItems={[
                    {
                      name: t("S&OPList"),
                      active: true,
                      href: "/snop",
                    },
                  ]}
                />
              </GridMUI>
            </GridMUI>
          </div>

          <div className="client-listing-content">
            <div className="client-listing-toolbar">
              <div className="client-listing-toolbar-left">
                <div className="client-listing-toolbar-left-search">
                  <TextField
                    label={t("S&OPName")}
                    sx={{ width: "100%" }}
                    name={"snop-search"}
                    onChange={handleChange}
                    value={searchField}
                    className="snop-toolbar-search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="client-listing-toolbar-left-show ml-10">
                  <Select
                    theme={createThemeSelect}
                    id="select-rows"
                    name="select-rows"
                    placeholder={`${t("Show")} 10 ${t("Rows")}`}
                    options={showMoreOptions || []}
                    value={{
                      label: `${t("Show")} ${pageSize} ${t("Rows")}`,
                      value: pageSize,
                    }}
                    onChange={(newPageSize) => {
                      setPageSize(newPageSize?.value);
                    }}
                    classNamePrefix="mySelect"
                    isDisabled={isEmpty(filteredSnopDetails)}
                    isSearchable={false}
                  />
                </div>
                <Button
                  buttonType={"refresh"}
                  className="ml-10"
                  onClick={onRefresh}
                />
              </div>

              <div className="client-listing-toolbar-right">
                {addPermission && (
                  <div className="client-listing-toolbar-right-add-client">
                    <Button
                      label={t("S&OPCreate")}
                      icon={
                        <i
                          className="fa fa-plus-circle ml-5 create-button"
                          aria-hidden="true"
                        ></i>
                      }
                      iconPosition={"end"}
                      buttonType={"outline-primary"}
                      className={"ml-15"}
                      onClick={(event) => onActionClick(event, "Create S&OP")}
                    />
                  </div>
                )}
                {editPermission && (
                  <div className="client-listing-toolbar-right-csv-download ml-10">
                    <Button
                      icon={<MdModeEditOutline />}
                      iconPosition={"end"}
                      buttonType={"primary"}
                      onClick={(event) => onActionClick(event, "Edit S&OP")}
                      disabled={
                        editItem.length === 0 ||
                        (editItem.length === 1 &&
                          editItem[0].status !== "IN_PROGRESS")
                      }
                    />
                  </div>
                )}
                <div className="client-listing-toolbar-right-add-client ml-10">
                  <Button
                    label={t("S&OPActivateButton")}
                    icon={<i className="fa fa-eye ml-5"></i>}
                    iconPosition={"end"}
                    buttonType={"primary"}
                    onClick={onViewClick}
                    disabled={
                      editItem.length !== 1 ||
                      isCreateOrEditClicked ||
                      isSnopActivating
                    }
                  />
                </div>
              </div>
            </div>
            {
              <div className="tablegrid">
                {!isEmpty(filteredSnopDetails) ? (
                  <>
                    <Grid
                      pageable={true}
                      sortable={{
                        allowUnsort: false,
                      }}
                      data={process(filteredSnopDetails, dataState)}
                      {...dataState}
                      onDataStateChange={(e) => {
                        setDataState(e.dataState);
                      }}
                      className={"k-tablegrid-custom k-text-elipsis mb-3"}
                    >
                      <Column
                        headerClassName="tablegrid-heading tablegrid-heading-no-sorting"
                        width={50}
                        sortable={false}
                        cell={(props) => {
                          const snop = props.dataItem;
                          const obj =
                            filteredSnopDetails &&
                            filteredSnopDetails?.filter(
                              (item) => item.snop_id === snop.snop_id
                            );
                          return (
                            <td className="p-0">
                              <input
                                type="checkbox"
                                className="d-block m-auto"
                                value={editItem}
                                // checked={editItem.includes(obj[0])}
                                checked={
                                  editItem?.length > 0 && editItem.find((item) => item.snop_id === snop.snop_id)
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setEditItem(
                                      filteredSnopDetails &&
                                      filteredSnopDetails?.filter(
                                        (item) =>
                                          item.snop_id === snop.snop_id
                                      )
                                    );
                                  } else {
                                    setEditItem(
                                      editItem &&
                                      editItem.filter(
                                        (item) =>
                                          item.snop_id !== snop.snop_id
                                      )
                                    );
                                  }
                                }}
                              />
                            </td>
                          );
                        }}
                      />
                      <Column
                        title=""
                        headerClassName={"tablegrid-heading"}
                        width={45}
                        sortable={false}
                        cell={(props) => {
                          return (
                            <td>
                              <div className="info-icon">
                                <InfoIcon onClick={() => handleInfoClick(props.dataItem)} />
                              </div>
                            </td>
                          );
                        }}
                      />
                      <Column
                        title="Active S&OP"
                        headerClassName={
                          "tablegrid-heading tablegrid-heading-no-sorting"
                        }
                        width={80}
                        sortable={false}
                        cell={(props) => {
                          let activeSNOP = activeSnopDetails["snop_id"];
                          return (
                            <td className="is-active-snop-container p-0">
                              {activeSNOP === props.dataItem.snop_id ? (
                                <Tooltip
                                  title="Activated"
                                  TransitionComponent={Zoom}
                                  placement="left"
                                  arrow
                                  disableInteractive
                                  PopperProps={ { disablePortal: fullscreenEnabled }}
                                >
                                  <TickElement
                                    width={15}
                                    height={"auto"}
                                    className="m-auto d-block"
                                  />
                                </Tooltip>
                              ) : (
                                <></>
                              )}
                            </td>
                          );
                        }}
                      />
                      <Column
                        field="snop_name"
                        title={t("S&OPName")}
                        headerClassName={"tablegrid-heading"}
                        cell={(props) => {
                          return (
                            <td>
                              <Tooltip
                                title={props.dataItem.snop_name}
                                TransitionComponent={Zoom}
                                placement="right"
                                arrow
                                disableInteractive
                                PopperProps={ {
                                  disablePortal: fullscreenEnabled
                                }}
                              >
                                <span>
                                  {props.dataItem.snop_name.length > 8
                                    ? `${props.dataItem.snop_name.slice(
                                      0,
                                      8
                                    )}...`
                                    : props.dataItem.snop_name}
                                </span>
                              </Tooltip>
                            </td>
                          );
                        }}
                      />
                      <Column
                        field="from_date"
                        title={t("S&OPFrom")}
                        headerClassName={"tablegrid-heading"}
                        cell={(props) => {
                          return (
                            <td>
                              <span>
                                {moment(props.dataItem.from_date).format(
                                  localeConfiguration[
                                    "dateFormat"
                                  ]?.toUpperCase()
                                )}
                              </span>
                            </td>
                          );
                        }}
                      />
                      <Column
                        field="to_date"
                        title={t("S&OPTo")}
                        headerClassName={"tablegrid-heading"}
                        cell={(props) => {
                          return (
                            <td>
                              <span>
                                {moment(props.dataItem.to_date).format(
                                  localeConfiguration[
                                    "dateFormat"
                                  ]?.toUpperCase()
                                )}
                              </span>
                            </td>
                          );
                        }}
                      />
                      <Column
                        field="forecast_trigger_date"
                        title={t("forecast_trigger_date")}
                        width={120}
                        headerClassName={"tablegrid-heading"}
                        cell={(props) => {
                          return (
                            <td>
                              <span>
                                {moment(
                                  props.dataItem.forecast_trigger_date
                                ).format(
                                  localeConfiguration[
                                    "dateFormat"
                                  ]?.toUpperCase()
                                )}
                              </span>
                            </td>
                          );
                        }}
                      />
                      <Column
                        field="demand_review_date"
                        title={t("S&OPDemandReview")}
                        headerClassName={"tablegrid-heading"}
                        width={120}
                        cell={(props) => {
                          return (
                            <td>
                              <span>
                                {moment(
                                  props.dataItem.demand_review_date
                                ).format(
                                  localeConfiguration[
                                    "dateFormat"
                                  ]?.toUpperCase()
                                )}
                              </span>
                            </td>
                          );
                        }}
                      />
                      <Column
                        field="supply_review_date"
                        title={t("S&OPSupplyReview")}
                        headerClassName={"tablegrid-heading"}
                        cell={(props) => {
                          return (
                            <td>
                              <span>
                                {moment(
                                  props.dataItem.supply_review_date
                                ).format(
                                  localeConfiguration[
                                    "dateFormat"
                                  ]?.toUpperCase()
                                )}
                              </span>
                            </td>
                          );
                        }}
                      />
                      <Column
                        field="pre_snop_date"
                        title={t("S&OPPreSNOP")}
                        headerClassName={"tablegrid-heading"}
                        cell={(props) => {
                          return (
                            <td>
                              <span>
                                {moment(props.dataItem.pre_snop_date).format(
                                  localeConfiguration[
                                    "dateFormat"
                                  ]?.toUpperCase()
                                )}
                              </span>
                            </td>
                          );
                        }}
                      />
                      <Column
                        field="snop_date"
                        title={t("S&OPSNOP")}
                        headerClassName={"tablegrid-heading"}
                        cell={(props) => {
                          return (
                            <td>
                              <span>
                                {moment(props.dataItem.snop_date).format(
                                  localeConfiguration[
                                    "dateFormat"
                                  ]?.toUpperCase()
                                )}
                              </span>
                            </td>
                          );
                        }}
                      />

                      <Column
                        field="status"
                        title={t("S&OPStatus")}
                        headerClassName={"tablegrid-heading"}
                        // width={170}
                        cell={(props) => {
                          return (
                            <td className="tablegrid-status">
                              <span
                                className={
                                  props.dataItem.status === "IN_PROGRESS"
                                    ? "tablegrid-status-inprogress"
                                    : props.dataItem.status ===
                                      "PLANNED_EXECUTED"
                                      ? "tablegrid-status-executed"
                                      : props.dataItem.status === "PLANNED"
                                        ? "tablegrid-status-executing"
                                        : ""
                                }
                              >
                                {props.dataItem.status === "IN_PROGRESS"
                                  ? t("S&OPStatusInProgress")
                                  : props.dataItem.status === "PLANNED_EXECUTED"
                                    ? t("S&OPStatusPlannedExecuted")
                                    : props.dataItem.status === "PLANNED"
                                      ? t("S&OPStatusPlannedExecuting")
                                      : ""}
                              </span>
                            </td>
                          );
                        }}
                      />
                    </Grid>
                  </>
                ) : (
                  <div className="text-center">No records found</div>
                )}
              </div>
            }
          </div>
        </section>
      </div>
      {modalOpen && (
        <PopupDialog
          handleOpen={handleInfoClick}
          handleClose={handleInfoClose}
        >
          <Calendar selectedSnop={selectedSnop} snopEvents={snopEvents} />
        </PopupDialog>
      )}
    </>
  );
};

export default List;
