import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useContext } from "react";
import ContextProvider from "../../ContextProvider";
import LoaderGif from "../../assets/Loader.gif";
import "./style.scss";

const LoaderWithLabel = () => {
  const { fullscreenEnabled } = useContext(ContextProvider);
  return (
    <Dialog
      closeIcon={false}
      className={"custom-dialog-loader"}
      appendTo={
        fullscreenEnabled
          ? document.getElementById("snop-listing-page") || null
          : document.body
      }
    >
      <img src={LoaderGif} alt="loader" />
    </Dialog>
  );
};

export default LoaderWithLabel;
