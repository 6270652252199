import React from "react";
import { Grid } from "@mui/material";
import List from "./list";

export default function Snop() {
  return (
    <div id="snop-listing-page">
      <Grid container spacing={2} className="bg-white">
        <Grid item xs={12} md={12}>
          <List />
        </Grid>
      </Grid>
    </div>
  );
}
