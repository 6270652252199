import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useContext } from "react";
import ContextProvider from "../../ContextProvider";
import "./dialog.scss";
import { snopStatusPopoverId } from "../../utils/constants";

const PopupDialog = ({ handleOpen, handleClose, children }) => {
    const { fullscreenEnabled } = useContext(ContextProvider);
    return (
        <>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={handleOpen}
                onClose={handleClose}
                disablePortal={fullscreenEnabled}
                id={snopStatusPopoverId}
            >
                <DialogContent className="dialog-content">
                    <Box className="dialog-box">
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            className="icon-button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <div>
                        {children}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PopupDialog;
