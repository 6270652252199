import i18next from "i18next";
import { toast } from "react-toastify";
import { put, takeEvery } from "redux-saga/effects";
import {
  createSnopAPI,
  deleteSnopAPI,
  editSnopAPI,
  getAllSnopAPI,
  snopCalendarAPI,
} from "../../../apis";
import {
  createSnopSlice,
  deleteSnopSlice,
  editSnopSlice,
  getAllSnopSlice,
  setLoader,
  setModalOpen,
  snopStatusSlice,
} from "../../slices/snop";
import { CREATE_SNOP, DELETE_SNOP, EDIT_SNOP, GET_ALL_SNOP, GET_SNOP_STATUS } from "../../types";

export function* getAllSnopSaga() {
  try {
    const snop = yield getAllSnopAPI();
    yield put(getAllSnopSlice(snop?.data?.responseData?.data ?? []));
  } catch (error) {
    yield put(getAllSnopSlice([]));
    toast.error(
      error?.response?.data?.responseData?.responseCode
        ? i18next.t(error?.response?.data?.responseData?.responseCode)
        : i18next.t("GENERIC_ERROR_MESSAGE")
    );
  }
}

export function* createSnopSaga(action) {
  try {
    yield put(
      createSnopSlice({
        data: {},
        isCreated: false,
        loader: true,
      })
    );
    const response = yield createSnopAPI(action.payload);
    yield put(
      createSnopSlice({
        data: response.data.responseData.data,
        isCreated: true,
        loader: true,
      })
    );
    toast.success(i18next.t("SNOP_CREATE_SUCCESS"));
    const snop = yield getAllSnopAPI();
    yield put(getAllSnopSlice(snop?.data?.responseData?.data));
  } catch (error) {
    yield put(
      createSnopSlice({
        data: {},
        isCreated: false,
        loader: false,
      })
    );
    toast.error(
      error?.response?.data?.responseData?.responseCode
        ? i18next.t(error?.response?.data?.responseData?.responseCode)
        : i18next.t("GENERIC_ERROR_MESSAGE")
    );
  }
}

export function* editSnopSaga(action) {
  try {
    yield put(
      editSnopSlice({
        data: {},
        isCreated: false,
        loader: true,
      })
    );
    const response = yield editSnopAPI(action.payload);
    yield put(
      editSnopSlice({
        data: response.data.responseData.data,
        isCreated: true,
        loader: false,
      })
    );
    toast.success(i18next.t("SNOP_UPDATE_SUCCESS"));
  } catch (error) {
    yield put(
      editSnopSlice({
        data: {},
        isCreated: false,
        loader: false,
      })
    );
    toast.error(
      error?.response?.data?.responseData?.responseCode
        ? i18next.t(error?.response?.data?.responseData?.responseCode)
        : i18next.t("GENERIC_ERROR_MESSAGE")
    );
  }
}

export function* deleteSnopSaga(action) {
  try {
    yield put(
      deleteSnopSlice({
        data: "",
        isDelete: false,
        loader: true,
      })
    );
    yield deleteSnopAPI(action.payload);
    yield put(
      deleteSnopSlice({
        data: action.payload,
        isDelete: true,
        loader: false,
      })
    );
    toast.success(i18next.t("SNOP_DELETE_SUCCESS"));
    const snop = yield getAllSnopAPI();
    yield put(getAllSnopSlice(snop?.data?.responseData?.data));
  } catch (error) {
    yield put(
      deleteSnopSlice({
        data: action.payload,
        isDelete: false,
        loader: false,
      })
    );
    toast.error(
      error?.response?.data?.responseData?.responseCode
        ? i18next.t(error?.response?.data?.responseData?.responseCode)
        : i18next.t("GENERIC_ERROR_MESSAGE")
    );
  }
}

export function* getSnopStatusSaga(action) {
  try {
    yield put(
      setLoader(true)
    );
    const res = yield snopCalendarAPI(action.payload);
    yield put(
      setLoader(false)
    );
    if (res.status == "200") {
      yield put(
        setModalOpen(true)
      );
      yield put(
        snopStatusSlice(res?.data?.responseData?.data)
      );
    }
  } catch (error) {
    yield put(
      setLoader(false)
    );
    toast.error(
      error?.response?.data?.responseData?.responseCode
        ? i18next.t(error?.response?.data?.responseData?.responseCode)
        : i18next.t("GENERIC_ERROR_MESSAGE")
    );
  }
}

export function* watchSnopSaga() {
  yield takeEvery(GET_ALL_SNOP, getAllSnopSaga);
  yield takeEvery(EDIT_SNOP, editSnopSaga);
  yield takeEvery(CREATE_SNOP, createSnopSaga);
  yield takeEvery(DELETE_SNOP, deleteSnopSaga);
  yield takeEvery(GET_SNOP_STATUS, getSnopStatusSaga);
}
