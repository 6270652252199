import get from "lodash/get";

export const SNOP_API_ENDPOINT = process.env.REACT_APP_SNOP_API_ENDPOINT;
export const SCAI_API_BASEURL_SNOP =
  process.env.REACT_APP_SCAI_API_BASEURL_SNOP;
export const SCAI_API_BASEURL_DPAI =
  process.env.REACT_APP_SCAI_API_BASEURL_DPAI;

const getLSItem = (key) => {
  let data = {};
  try {
    if (localStorage.getItem(key)) data = JSON.parse(localStorage.getItem(key));
  } catch {
    data = {};
  }
  return data;
};
// User
export const user = getLSItem("user");
export const permissions = get(user, "permissions", []);

export const tenantId = get(user, "tenant_id", "");
export const selected_business_unit = getLSItem("selected_business_unit");
export const buId = get(selected_business_unit, "business_unit_id", "");

// token
export const token = getLSItem("token");

// configuration
export const configuration = getLSItem("configuration");
export const localeConfiguration = configuration?.locale || {};
export const snopConfiguration = configuration?.snop || {};

// snop
export const activeSnopDetails = getLSItem("snop");
